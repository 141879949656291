<template>
  <v-app>
    <v-main>
      <HomePage @changeOpacity="changeOpacity"/>
    </v-main>
  </v-app>
</template>

<script>
import HomePage from './components/HomePage';

export default {
  name: 'App',

  components: {
    HomePage,
  },

  data: () => ({
    navColor: '#424242'
  }),

  methods:{
    changeOpacity(){
      var app = this;
      this.navColor = "#424242a2"

      window.addEventListener("scroll", function() {
  
          var top = this.scrollY
        
          if(top == 0){
            app.navColor = '#424242'
          }
        
      }, false);
    }
  }
};
</script>


<style scoped>
*{
  font-family: 'Roboto', sans-serif;
}
#i{
  color: #424242a6
}
.title-logo{
  font-family: 'Tilt Warp', cursive;
}
</style>