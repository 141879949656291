<template>
  <v-container fluid>
    <!-- TOPO -->

    <!-- DESKTOP -->
    <v-row id="bg" v-if="isMobile == false">
      <v-col cols="12" md="12" id="txt"> 
        <div class="title-small">Torne-se uma <span>designer de sobrancelhas</span> de</div>
          <h1 class="title2">SUCESSO</h1>
      </v-col>
      
      <v-container>
        <v-row>
          <v-col cols="12" md="6" offset-md="3" id="video" @click="playVideo"> 
            <div class="card-video" @click="playVideo">
              <iframe @click="playVideo" width="100%" height="315" src="https://www.youtube.com/embed/VcGvodzQMeg?si=hwKLw_8jC8NL4Mt-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-row id="section5">
        <v-col cols="12">
          <v-row>
            <v-col md="6" offset-md="3">
              <v-row>
                <v-col cols="12" md="12" class="btn-div-top">
                  <div class="btn-acao-top" @click="scrollToForm" color="#fff" v-if="click == false" rounded large>EU QUERO!</div>
                  <div class="btn-acao-top" disabled color="#fff" v-if="click" rounded large>AGUARDE...</div>
                </v-col>
              </v-row>
            </v-col> 
          </v-row>
        </v-col>
      </v-row>
        
      
    </v-row>

    <!-- MOBILE -->
    <v-row id="bg-mobile" v-if="isMobile == true">
      <v-col cols="12" md="12" id="txt"> 
        <div class="title-small">Torne-se uma <span>designer de sobrancelhas</span> de 0</div>
          <h1 class="title2">SUCESSO</h1>
      </v-col>
      
      <v-container @click="playVideo">
        <v-row>
          <v-col cols="12" md="6" id="video"> 
            <div class="card-video">
              <iframe @click="playVideo" width="100%" height="315" src="https://www.youtube.com/embed/VcGvodzQMeg?si=hwKLw_8jC8NL4Mt-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" autoka allowfullscreen></iframe>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-row id="section5">
        <v-col cols="12">
          <v-row>
            <v-col md="6" offset-md="3">
              <v-row>
                <v-col cols="12" md="12" class="btn-div-top-mobile">
                  <div class="btn-acao-top-mobile" @click="scrollToForm" color="#fff" v-if="click == false" rounded large>EU QUERO!</div>
                  <div class="btn-acao-top-mobile" disabled color="#fff" v-if="click" rounded large>AGUARDE...</div>
                </v-col>
              </v-row>
            </v-col> 
          </v-row>
        </v-col>
      </v-row>
      
    </v-row>

    
  </v-container>
</template>

<script>
/* eslint-disable */

import {mask} from 'vue-the-mask'
import axios from 'axios'


  export default {
    name: 'HomePage',
    directives: {mask},
    data(){
      return{
        isMobile: true,
        nome: "",
        email: "",
        telefone: "",
        preferencia: "",
        click: false,
      }
    },

    created () {
      window.addEventListener('scroll', this.handleScroll);
    },


    async mounted(){
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      let remote = await axios.get("https://api.ipify.org?format=json");

      let dadosIp = await axios.get(`https://apiip.net/api/check?ip=${remote.data.ip}&accessKey=4a7f2c14-063d-448f-a7eb-3a139074a5ef`)
      let mensagem = `*NOVO ACESSO*
IP: ${remote.data.ip}
CIDADE: ${dadosIp.data.city}
UA: ${navigator.userAgent}
${new Date().toLocaleString()}`
      
      await this.enviaWhatsapp('11978842814', mensagem)

    },

    methods: {
      handleScroll (event) {
        this.$emit("changeOpacity", event)
      },
      playVideo(){
        fbq('track', 'ClicouNoVideo');
      },
      async scrollToForm(){
        this.click = true;
        let remote = await axios.get("https://api.ipify.org?format=json");

        let dadosIp = await axios.get(`https://apiip.net/api/check?ip=${remote.data.ip}&accessKey=4a7f2c14-063d-448f-a7eb-3a139074a5ef`)
        
      let mensagem = `*CLICOU*
IP: ${remote.data.ip}
CIDADE: ${dadosIp.data.city}
UA: ${navigator.userAgent}
${new Date().toLocaleString()}`
        
        await this.enviaWhatsapp('11978842814', mensagem)
        fbq('track', 'ClickEuQuero');
        ttq.track('CliqueEuQuero', {});
        window.location.href = 'https://chat.whatsapp.com/I1dknfxM1Uw8H7ZmAqet69';
      },
      async enviaWhatsapp(numero, mensagem){
          let ins = {
              numero,
              mensagem
          }
          await axios.post(`https://nagenda-api.onrender.com/enviaWhatsapp`, ins)
      }
    }
  }
</script>

<style scoped>
/* DESKTOP */
  #bg{
    background-color: #b8b8b8;
    background: url(../assets/bg.jpg);
    background-size: 100%;
  }
  
  #bg #txt{
    text-align: center;
  }
  #bg .title-small{
    font-weight: 500;
    color: #fff;
    font-size: 25pt;
    margin-bottom: -10px;
    margin-top: 30px;
    text-shadow: 1px 2px 2px #000;
  }
  #bg .title-small span{
    color: #de9cb2;
  }

  #bg .title2{
    font-family: 'Tilt Warp', cursive;
    font-size: 60pt;
    letter-spacing: 0rem;
    line-height: 6.4rem;
    color: #fff;
    text-shadow: 2px 3px 20px #000;
  }

  #bg #video{
    padding-left: 10px;
  }

  #bg #txt2 h6{
    color: #fff;
    font-size: 20pt;
    margin-top: 200px;
  }

/* MOBILE */

  #bg-mobile{
    background-color: #b8b8b8;
    background: url(../assets/bg.jpg) no-repeat;
    background-size: 300% 100%;
  }

  #bg-mobile .title-small{
    font-weight: 500;
    color: #fff;
    font-size: 25pt;
    margin-bottom: -10px;
    margin-top: 30px;
    text-align: center;
    text-shadow: 1px 2px 2px #000;
  }
  #bg-mobile .title-small span{
    color: #fcc7c7;
  }

  #bg-mobile .title2{
    font-family: 'Tilt Warp', cursive;
    font-size: 28pt;
    text-align: center;
    color: #fff;
    text-shadow: 2px 3px 20px #444444dc;
  }

  #bg-mobile #video{
    padding-left: 10px;
  }

  #bg-mobile #txt2 h6{
    color: #fff;
    font-size: 20pt;
    margin-top: 10px;
  }

  .btn-div-top-mobile{
    text-align: center;
  }
  .btn-acao-top-mobile{
    cursor: pointer;
    display: block;
    margin: 0 auto;
    background-color: #de9cb2;
    border-radius: 50px;
    color: #fff;
    font-size: 20pt;
    font-family: 'Tilt Warp', cursive;
    width: 80%;
    margin-top: 40px;
    margin-bottom: 60px;
  }

  #video-mobile{
    padding-top: 60px;
  }
  #video-mobile iframe{
    width: 100%;
  }

  .btn-div-top{
    text-align: center;
  }
  .btn-acao-top{
    cursor: pointer;
    display: block;
    margin: 0 auto;
    background-color: #de9cb2;
    border-radius: 50px;
    color: #fff;
    font-size: 40pt;
    font-family: 'Tilt Warp', cursive;
    width: 50%;
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .btn-acao{
    color: #fff;
    font-size: 17pt;
    font-family: 'Tilt Warp', cursive;
    font-style: italic;
  }






  /* SECTION 1 */
  #section1{
    background-color: #790505;
    color: #fff;
    padding: 20px;
  }

  #section1 .titulo{
    text-align: center;
    font-family: 'Tilt Warp', cursive !important;
    font-style: italic;
    font-size: 2rem;
    letter-spacing: 2px;
  }

  #section1 .texto p{
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12pt;
    font-weight: 500;
  }

  #section1 .texto-mobile{
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12pt;
  }

  #section1 .botao div{
    cursor: pointer;
    display: block;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 50px;
    color: #8b1010;
    font-size: 25pt;
    font-family: 'Tilt Warp', cursive;
    width: 30%;
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: center;
  }

  #section1 .botao-mobile div{
    cursor: pointer;
    display: block;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 50px;
    color: #8b1010;
    font-size: 25pt;
    font-family: 'Tilt Warp', cursive;
    width: 80%;
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: center;
  }





  /* SECTION 2 */
  #section2{
    background: url(../assets/bg-s2.jpg) no-repeat;
    background-size: 100% 100%;

  }

  #section2 h2{
    text-align: center;
    color: #fff;
    margin-top: 30px;
    font-size: 30pt;
  }

  #section2 #texto-principal-card{
    background-color: #fff;
    width: 60%;
    padding: 15px;
    display: block;
    margin: 0 auto;
    border-radius: 30px;
    border: 2.3pt solid #790505;
    text-align: center;
    padding-bottom: 150px;
  }

  #section2 #texto-principal-card h4{
    text-align: center;
    color: #860f0f;
    font-weight: 800;
  }

  #section2 #texto-principal-card p{
    text-align: center;
    font-size: 9pt;
    width: 70%;
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    font-weight: 500;
  }


  #section2 #texto-direita-card{
    position: absolute;
    right: 250px;
    margin-top: -130px;
    background-color: #fff;
    width: 44%;
    padding: 20px 30px 20px 30px;
    border-radius: 40px;
    border: 2.3pt solid #790505;
    text-align: right;
    font-weight: bold;
  }

  #section2 #texto-direita-card p{
    font-size: 9pt;
  }

  #section2 #texto-esquerda-card{
    position: absolute;
    left: 160px;
    margin-top: -130px;
    background-color: #fff;
    /* width: 44%; */
    padding: 20px 40px 20px 40px;
    border-radius: 40px;
    border: 2.3pt solid #790505;
    text-align: center;
    font-weight: bold;
    z-index: 0;
  }

  #section2 #texto-esquerda-card p{
    font-size: 12pt;
  }

  #section2 #texto-esquerda-card p span{
    color:#860f0f;
  }

  #section2 .espaco{
    height: 170px;
  }

  #section2 .btn-acao-s2{
    position: absolute;
    right: 370px;
    cursor: pointer;
    text-align: center;
    background-color: #860f0f;
    border-radius: 70px;
    color: #fff;
    font-size: 40pt;
    font-family: 'Tilt Warp', cursive;
    width: 40%;
    margin-top: -10px;
    margin-bottom: 60px;
    z-index: 999;
    padding: 15px;
    border: 3px solid #fff
  }



  /* SECTION 2 - MOBILE */
  #section2-mobile{
    background: url(../assets/bg-s2.jpg) no-repeat;
    background-size: 100% 100%;

  }

  #section2-mobile h2{
    text-align: center;
    color: #fff;
    margin-top: 30px;
    font-size: 20pt;
  }

  #section2-mobile #texto-principal-card{
    background-color: #fff;
    width: 100%;
    padding: 15px;
    display: block;
    margin: 0 auto;
    border-radius: 30px;
    border: 2.3pt solid #790505;
    text-align: center;
  }

  #section2-mobile #texto-principal-card h4{
    text-align: center;
    color: #860f0f;
    font-weight: 800;
  }

  #section2-mobile #texto-principal-card p{
    text-align: center;
    font-size: 9pt;
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    font-weight: 500;
  }


  #section2-mobile #texto-direita-card{
    margin-top: 10px;
    background-color: #fff;
    width: 100%;
    padding: 20px 30px 20px 30px;
    border-radius: 40px;
    border: 2.3pt solid #790505;
    text-align: right;
    font-weight: bold;
  }

  #section2-mobile #texto-direita-card p{
    font-size: 9pt;
  }

  #section2-mobile #texto-esquerda-card{
    /* position: absolute; */
    left: 160px;
    margin-top: 10px;
    background-color: #fff;
    /* width: 44%; */
    padding: 20px 40px 20px 40px;
    border-radius: 40px;
    border: 2.3pt solid #790505;
    text-align: center;
    font-weight: bold;
    z-index: 0;
  }

  #section2-mobile #texto-esquerda-card p{
    font-size: 12pt;
  }

  #section2-mobile #texto-esquerda-card p span{
    color:#860f0f;
  }

  #section2-mobile .espaco{
    /* height: 10px; */
  }

  #section2-mobile .btn-acao-s2{
    display: block;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
    background-color: #860f0f;
    border-radius: 70px;
    color: #fff;
    font-size: 40pt;
    font-family: 'Tilt Warp', cursive;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 60px;
    z-index: 999;
    padding: 15px;
    border: 3px solid #fff
  }





 /* SECTION 3 */
 #section3{
    background-color: #790505;
    color: #fff;
    padding: 20px;
  }

  #section3 .titulo{
    text-align: center;
    font-family: 'Tilt Warp', cursive !important;
    font-size: 2rem;
    letter-spacing: 2px;
  }

  #section3 .texto p{
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12pt;
    font-weight: 500;
  }

  #section3 .texto-mobile{
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12pt;
  }

  #section3 .botao div{
    cursor: pointer;
    display: block;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 50px;
    color: #8b1010;
    font-size: 25pt;
    font-family: 'Tilt Warp', cursive;
    width: 30%;
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: center;
  }
  #section3 .botao-mobile div{
    cursor: pointer;
    display: block;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 50px;
    color: #8b1010;
    font-size: 25pt;
    font-family: 'Tilt Warp', cursive;
    width: 70%;
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: center;
  }



  /* SECTION 4 */
  #section4{
    background: url(../assets/bg-s4.jpg);
    background-size: 100% 100%;
    padding: 50px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
  }

  #section4 .texto-mobile{
    font-size: 9pt;
  }

  #section4 .btn-acao-s4{
    display: block;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
    background-color: #860f0f;
    border-radius: 70px;
    color: #fff;
    font-size: 40pt;
    font-family: 'Tilt Warp', cursive;
    width: 40%;
    margin-top: -10px;
    margin-bottom: 60px;
    z-index: 999;
    border: 3px solid #fff
  }

  #section4 .btn-acao-s4-mobile{
    display: block;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
    background-color: #860f0f;
    border-radius: 70px;
    color: #fff;
    font-size: 30pt;
    font-family: 'Tilt Warp', cursive;
    width: 90%;
    margin-top: -10px;
    margin-bottom: 60px;
    z-index: 999;
    border: 3px solid #fff
  }



  /* SECTION 4.5 */
  #section45{
    background-color: #790505;
    color: #fff;
    padding: 20px;
  }

  #section45 .titulo{
    text-align: center;
    font-family: 'Tilt Warp', cursive !important;
    font-style: italic;
    font-size: 2rem;
    letter-spacing: 1px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  #section45 .botao div{
    display: block;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
    background-color: #790505;
    border-radius: 70px;
    color: #fff;
    font-size: 30pt;
    font-family: 'Tilt Warp', cursive;
    width: 34%;
    margin-top: -10px;
    margin-bottom: 60px;
    z-index: 999;
    border: 3px solid #fff
  }


  /* SECTION 4.5 - MOBILE */
  #section45-mobile{
    background-color: #790505;
    color: #fff;
    padding: 15px;
  }

  #section45-mobile .titulo{
    text-align: center;
    font-family: 'Tilt Warp', cursive !important;
    font-style: italic;
    font-size: 1.6rem;
    letter-spacing: 1px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  #section45-mobile .botao div{
    display: block;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
    background-color: #790505;
    border-radius: 70px;
    color: #fff;
    font-size: 30pt;
    font-family: 'Tilt Warp', cursive;
    width: 70%;
    margin-top: -10px;
    margin-bottom: 60px;
    z-index: 999;
    border: 3px solid #fff
  }

  /* SECTION 5 */
  #section5{
    background-color: rgb(233, 112, 152);
    color: #fff;
    padding: 20px;
  }

  #section5 .titulo{
    text-align: center;
    font-family: 'Tilt Warp', cursive !important;
    font-style: italic;
    font-size: 2rem;
    letter-spacing: 2px;
  }


  .input-div input, .input-div select{
    border: 1.5pt solid #fff;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

</style>
